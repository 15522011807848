<template>
  <div>

    <b-modal
      :visible="isSidebarActive"
      title="Payout Account Detail"
      size="xl"
      hide-footer
      centered
      :no-close-on-backdrop="true"
      @change="(val) => isSidebarActive = val"
    >
      <payout-account-detail
        :info="accountInfo"
        :is-sidebar-active.sync="isSidebarActive"
        @updated="fetchByMerchant"
      />
    </b-modal>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="addNewAccount"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </b-col>

        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="accounts"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(account)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block"
              @click="showDetail(data.item.id)"
            >
              {{ data.item.account_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.account_id }}</small>
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(default)="data">
          <b-badge
            pill
            :variant="`light-${resolveDefaultVariant(data.item.is_default)}`"
            class="text-capitalize"
          >
            {{ resolveDefaultText(data.item.is_default) }}
          </b-badge>
        </template>

        <template #cell(opt)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, BMedia, BLink, BBadge,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'
import PayoutAccountDetail from './PayoutAccountDetail.vue'

const {
  findPayoutAccountById,
  listPayoutAccountByMerchant,
  deletePayoutAccount,
} = useMerchantsJs()

export default {
  components: {
    PayoutAccountDetail,

    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BMedia,
    BLink,
    BBadge,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      tableFields: [
        'account',
        'supported_currencies',
        'default',
        'status',
        'opt',
      ],
      accounts: [],
      accountInfo: {},

      isSidebarActive: false,
    }
  },
  created() {
    this.fetchByMerchant()
  },
  methods: {
    fetchByMerchant() {
      listPayoutAccountByMerchant(
        this.merchantNo,
        accounts => {
          this.accounts = accounts
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveDefaultVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },
    resolveDefaultText(isDefault) {
      if (isDefault === 0) return 'N'
      if (isDefault === 1) return 'Y'
      return 'Unknown'
    },
    addNewAccount() {
      this.accountInfo = {
        merchant_no: this.merchantNo,
        is_default: 0,
        status: '0',
      }
      this.isSidebarActive = true
      setTimeout(() => {
        this.accountInfo = {
          merchant_no: this.merchantNo,
          is_default: 0,
          status: '0',
        }
      }, 500)
    },
    deleteConfirm(item) {
      this.$swal({
        title: 'Are you sure?',
        text: `DELETE Payout Account [${item.account_name}]`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteAccount(item)
        }
      })
    },
    deleteAccount(item) {
      deletePayoutAccount(
        item.id,
        () => {
          showAlert(this, 'success', 'Deleted!', 'Success')
          this.fetchByMerchant()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    showDetail(id) {
      findPayoutAccountById(
        id,
        account => {
          this.isSidebarActive = true
          setTimeout(() => {
            this.accountInfo = account
          }, 500)
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
