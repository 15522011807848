<template>
  <!-- Body -->
  <b-row>
    <b-col cols="12" md="3">
      <!-- ID -->
      <validation-observer ref="accountIdValid">
        <b-form-group label="ID" label-for="account-id">
          <validation-provider
            #default="{ errors }"
            name="Account ID"
            rules="required"
          >
            <b-form-input
              id="account-id"
              v-model="account.account_id"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-col>
    <!-- Name -->
    <b-col cols="12" md="3">
      <validation-observer ref="accountNameValid">
        <b-form-group label="Name" label-for="account-name">
          <validation-provider
            #default="{ errors }"
            name="Account Name"
            rules="required"
          >
            <b-form-input
              id="account-name"
              v-model="account.account_name"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-col>
    <!-- Status -->
    <b-col cols="12" md="3">
      <b-form-group
        label="Status"
        label-for="account-status"
      >
        <v-select
          v-model="account.status"
          :options="statusOptions"
          :reduce="val => val.value"
          :clearable="false"
          :searchable="false"
          input-id="account-status"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="3">
      <b-form-group
        label="Auto Transfer Account Default"
        label-for="transfer-default"
      >
        <v-select
          v-model="account.is_default"
          :options="isDefaultOptions"
          :reduce="val => val.value"
          :clearable="false"
          :searchable="false"
          input-id="transfer-default"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <hr>
      <br>
    </b-col>

    <!-- Supported Currencies & Markup Config -->
    <b-col>
      <b-form ref="form" :style="{height: trHeight}" class="repeater-form" @submit.prevent="repeateAgain">
        <validation-observer ref="MarkUpValid">

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in markups"
            :id="item.id"
            :key="item.id"
            ref="row"
          >

            <!-- Source -->
            <b-col md="3">
              <b-form-group label="Source Currency" label-for="source-currency">
                <validation-provider
                  #default="{ errors }"
                  name="Source Currency"
                  rules="required"
                >
                  <b-form-select
                    v-model="item.source"
                    :options="currencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Target -->
            <b-col md="3">
              <b-form-group label="Target Currency" label-for="target-currency">
                <validation-provider
                  #default="{ errors }"
                  name="Target Currency"
                  rules="required"
                >
                  <b-form-select
                    v-model="item.target"
                    :options="currencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Markup -->
            <b-col md="2">
              <b-form-group label="Markup" label-for="markup">
                <b-input-group append="%">
                  <validation-provider
                    #default="{ errors }"
                    name="Markup"
                    rules="required"
                  >
                    <b-form-input
                      id="markup"
                      v-model="item.markup"
                      type="number"
                      placeholder="Markup"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Source Currency Exchange Default"
                label-for="markup-default"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Exchange Default"
                  rules="required"
                >
                  <v-select
                    v-model="item.is_default"
                    :options="isDefaultOptions"
                    :reduce="val => val.value"
                    :state="errors.length > 0 ? false : null"
                    :clearable="false"
                    :searchable="false"
                    input-id="markup-default"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col md="2">
              <b-button
                block
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" />
                <span>Delete</span>
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr>
              <br>
            </b-col>
          </b-row>
        </validation-observer>

      </b-form>
    </b-col>

    <el-divider class="mt-1 mb-1" />

    <div class="d-flex">
      <b-button
        variant="primary"
        class="ml-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        type="submit"
        @click.prevent="showConfirm"
      >
        Submit
      </b-button>
    </div>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BFormSelect,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { showAlert } from '@/libs/tool'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useMerchantsJs from './merchants'

const {
  fetchCommonOptions,
  addPayoutAccount,
  updatePayoutAccount,
} = useMerchantsJs()

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BFormSelect,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      account: {
        id: '',
        merchant_no: '',
        account_id: '',
        account_name: '',
        account_key: '1234567890',
        status: '1',
        is_default: 0,
        markups: [],
      },
      markups: [],
      nextTodoId: 2,
      isAdd: false,
      statusOptions: [
        { label: 'Disable', value: '0' },
        { label: 'Enable', value: '1' },
      ],
      isDefaultOptions: [
        { label: 'Y', value: 1 },
        { label: 'N', value: 0 },
      ],

      currencyOptions: [],
    }
  },
  watch: {
    info: {
      handler(info) {
        if (!info) {
          return
        }
        this.isAdd = info.isAdd === true
        this.account = info
        if (this.isAdd) {
          this.account.is_default = 0
          this.account.status = '0'
        }
        if (info.markups) {
          info.markups.forEach((item, i) => {
            const markup = item
            markup.id = i + 1
            this.markups.push(markup)
            this.initTrHeight()
          })
        }
      },
    },
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)

    fetchCommonOptions(
      options => {
        const { currencies } = options

        currencies.forEach(item => {
          this.currencyOptions.push(item.code)
        })
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `Fetch options fail with ${fail}`)
      },
    )
  },
  methods: {
    repeateAgain() {
      this.markups.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.$swal({
        title: 'Are you sure Delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.markups.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        }
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    showConfirm() {
      if (this.markups.length < 1) {
        showAlert(this, 'warning', 'Fail!', 'Configuration at least one <Source-Target markup')
        return
      }
      this.$refs.accountIdValid.validate().then(success => {
        if (success) {
          this.$refs.accountNameValid.validate().then(success2 => {
            if (success2) {
              if (success2) {
                this.$refs.MarkUpValid.validate().then(success4 => {
                  if (success4) {
                    this.$swal({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Yes!',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {
                        this.update()
                      }
                    })
                  }
                })
              }
            }
          })
        }
      })
    },
    update() {
      this.account.account_key = '1234567890'
      this.account.markups = this.markups
      if (!this.account || !this.account.id) {
        addPayoutAccount(
          this.account,
          () => {
            showAlert(this, 'success', 'Created!', 'Success')
            this.$emit('update:is-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      } else {
        updatePayoutAccount(
          this.account,
          () => {
            showAlert(this, 'success', 'Updated!', 'Success')
            this.$emit('update:is-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
